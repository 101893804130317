import qs from 'qs';
import { Booking, Configuration, CountryCode, LanguageCode, StripeUiMode } from "@/types";

export function readConfigurationProperty(property: string, defaultValue?: any): any {
    if (!(window as any).BOKAMERA || !(window as any).BOKAMERA.configuration) {
        // console.log('No external configuration found for BOKAMERA');
        return defaultValue;
    }

    const setting = (window as any).BOKAMERA.configuration[property];

    if (setting === 'true') return true;

    if (setting === 'false') return false;

    if (setting === undefined) {
        return defaultValue;
    }

    return setting;
}

export function resizeParentIframe(size: number | void) {
    if (!("parentIFrame" in window)) {
      return;
    }
  
    if (typeof size === "number") {
      // @ts-ignore
      parentIFrame.autoResize(false);
      // @ts-ignore
      parentIFrame.size(size);
    } else {
      // @ts-ignore
      parentIFrame.autoResize(true);
    }
  }

  export function getErrorMessage(error: any) {
    return error?.response?.data?.ResponseStatus?.Message || error?.message;
  }


export const realmParamsMapping =  {
  bookmore: '1',
  ['bookmore-admin']: '2'
};

export const getRedirectUri = (backUriParam: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined) => {
  let redirectUri;

  // this is the redirect for the hosted embed-booking (in Iframe)
  if (Array.isArray(backUriParam)) {
    redirectUri = `${backUriParam[1]}?success=true`;
  }

  // this is the redirect for the NOT hosted embed-booking
  else if (typeof backUriParam === 'string') {
    redirectUri = `${backUriParam}?success=true`
  }

  return redirectUri;
};


export const getCountryCode = (language: LanguageCode): CountryCode => {
  if (language === "sv" || !language) {
    return "se";
  }

  return language;
};

/**
 * 
 * @param errorResponse usually in error.response.data 
 * @returns 
 */
export const getErrorMessageFromApiError = (error: any) => {
  let errorMessage;

  if (error.response?.data.ResponseStatus?.Message) {
    errorMessage = error.response.data.ResponseStatus.Message;
  } else if (error?.message) {
    errorMessage = error.message;
  } else {
    errorMessage = "Uknown error occured";
  }

  return errorMessage;
};

export const createArticlePaymentUrls = (
  configuration: Configuration,
  uiMode: StripeUiMode,
  articleId: number,
  customerEmail: string,
  cancellationCode?: string
) => {
  if (uiMode === "hosted") {
    return createHostedArticlePaymentUrls(configuration, articleId, customerEmail);
  } else {
    return createEmbeddedArticlePaymentUrls(articleId, customerEmail);
  }
  
};

const createHostedArticlePaymentUrls = (
  configuration: Configuration,
  articleId: number,
  customerEmail: string
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  let confirmationUrl;
  
  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    email: customerEmail,
    articleId
  });

  checkoutUrl.hash = "#/payment-failed";

  if (configuration.paymentConfirmationURL) {
    confirmationUrl = new URL(configuration.paymentConfirmationURL);
    confirmationUrl.search = qs.stringify({
      articleId
    });
  } else {
    confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
      {
        ...params,
        articleId,
        email: customerEmail
      },
      {}
    )}#/article-payment-success`)
  }

  return {
    confirmationUrl,
    checkoutUrl,
  };
}

export const createEmbeddedArticlePaymentUrls = (
  articleId: number,
  customerEmail: string
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  let confirmationUrl;
  
  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    email: customerEmail,
    articleId
  });
  checkoutUrl.hash = "#/payment-failed";

  confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
    {
      ...params,
      articleId,
      email: customerEmail
    },
    {}
  )}#/article-payment-success`);

  return {
    confirmationUrl,
    checkoutUrl,
  };
}

export const createPaymentUrls = (
  configuration: Configuration,
  uiMode: StripeUiMode = 'embedded',
  bookingId: number,
  customerEmail: string,
  cancellationCode?: string
) => {
  if (uiMode === "hosted") {
    return createHostedPaymentUrls(configuration, bookingId, customerEmail, cancellationCode);
  } else {
    return createEmbeddedPaymentUrls(configuration, bookingId, customerEmail, cancellationCode);
  }
};

const createHostedPaymentUrls = (
  configuration: Configuration,
  bookingId: number,
  customerEmail: string,
  cancellationCode?: string
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  let confirmationUrl;
  
  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    bookingId,
    email: customerEmail,
    ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
  });
  checkoutUrl.hash = "#/payment-failed";

  if (configuration.paymentConfirmationURL) {
    confirmationUrl = new URL(configuration.paymentConfirmationURL);
    confirmationUrl.search = qs.stringify({
      bookingId,
      email: customerEmail,
      ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
    });
  } else {
    confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
      {
        ...params,
        bookingId,
        email: customerEmail,
        ...(cancellationCode ? { cancellationCode: cancellationCode} : {})
      },
      {}
    )}#/payment-success`)
  }

  return {
    confirmationUrl,
    checkoutUrl,
  };
};

const createEmbeddedPaymentUrls = (
  configuration: Configuration,
  bookingId: number,
  customerEmail: string,
  cancellationCode?: string,
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });


  const confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
    {
      ...params,
      bookingId,
      email: customerEmail,
      ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
    },
    {}
  )}#/payment-success`)


  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    bookingId,
    email: customerEmail,
    ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
  });
  checkoutUrl.hash = "#/payment-failed";

  return {
    confirmationUrl,
    checkoutUrl,
  };
}


export const encryptionKey = process.env.REACT_APP_BOKAMERA_ENCRYPTION_KEY;
export const encryptionIVV = process.env.REACT_APP_BOKAMERA_ENCRYPTION_IVV_VALUE;

export const encryptSymmetric = async (plaintext: string, key: string) => {
  // create a random 96-bit initialization vector (IV)
  const iv = crypto.getRandomValues(new Uint8Array(12));

  // encode the text you want to encrypt
  const encodedPlaintext = new TextEncoder().encode(plaintext);

  // prepare the secret key for encryption
  const secretKey = await crypto.subtle.importKey('raw', Buffer.from(key, 'base64'), {
      name: 'AES-CBC',
      length: 256
  }, true, ['encrypt', 'decrypt']);

  // encrypt the text with the secret key
  const ciphertext = await crypto.subtle.encrypt({
      name: 'AES-CBC',
      iv
  }, secretKey, encodedPlaintext);
  
  // return the encrypted text "ciphertext" and the IV
  // encoded in base64
  return ({
      ciphertext: Buffer.from(ciphertext).toString('base64'),
      iv: Buffer.from(iv).toString('base64')
  });
}

export const decryptSymmetric = async (ciphertext: string, iv: string, key: string) => {
  // prepare the secret key
  const secretKey = await crypto.subtle.importKey(
      'raw',
      Buffer.from(key, 'base64'), 
      {
      name: 'AES-CBC',
      length: 256
  }, true, ['encrypt', 'decrypt']);

  // decrypt the encrypted text "ciphertext" with the secret key and IV
  const cleartext = await crypto.subtle.decrypt({
      name: 'AES-CBC',
      iv: Buffer.from(iv, 'base64'),
  }, secretKey, Buffer.from(ciphertext, 'base64'));

  // decode the text and return it
  return new TextDecoder().decode(cleartext);
}

export function getBrowserFlags() {
  if(window && window.navigator) {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    
    return {
      iOSSafari
    }
  }

  return {
    iOSSafari: false
  };
}

export const getXlanguageCode = () => {
  if(readConfigurationProperty('language') === "no") {
    return 'nb-no';
  }

  return readConfigurationProperty('language') || "sv"
}