import { useAppDispatch, useAppSelector } from "@/hooks/hook";
import { useGetRebateCodesQuery } from "@/services/bmApi";
import { Trans } from "@lingui/react";
import React, { useState } from "react";
import BEMHelper from "react-bem-helper";
import { Card, Table, Badge } from "reactstrap";
import "./RebateCodeUsages.css";
import RTKLoader from "./RTKLoader";
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";
import { RebateCode } from "@/types";
import { format } from "@/utils/localizedDateFns";
import { buyRebateCode } from "@/reducers/rebateCode";

const c = BEMHelper("RebateCodeUsages");

type Props = {};

const RebateCodeType: React.FC<{ rebateCodeTypeId?: number }> = ({
  rebateCodeTypeId: rebateCodeId,
}) => {
  switch (rebateCodeId) {
    case 1:
      return (
        <Badge>
          <Trans id="RebateCodeUsages.articleTypeId.1.text" />
        </Badge>
      );
    case 2:
      return (
        <Badge>
          <Trans id="RebateCodeUsages.articleTypeId.2.text" />
        </Badge>
      );
    case 3:
      return (
        <Badge>
          <Trans id="RebateCodeUsages.articleTypeId.3.text" />
        </Badge>
      );
    case 4:
      return (
        <Badge>
          <Trans id="RebateCodeUsages.articleTypeId.4.text" />
        </Badge>
      );
    case 5:
      return (
        <Badge>
          <Trans id="RebateCodeUsages.articleTypeId.5.text" />
        </Badge>
      );
    default:
      return null;
  }
};

const RebateCodeModal: React.FC<{
  rebateCode?: RebateCode;
  toggle: () => void;
}> = ({ rebateCode, toggle }) => {
  return (
    <Modal isOpen={!!rebateCode} toggle={toggle}>
      <ModalHeader>
        {rebateCode?.RebateCodeSign}{" "}
        <RebateCodeType rebateCodeTypeId={rebateCode?.RebateCodeTypeId} />
      </ModalHeader>
      {rebateCode ? (
        <ModalBody>
          {rebateCode.RebateCodeTypeId !== 3 ? (
            <p>
              <Trans id="RebateCodeUsages.rebateCodeValue" />:{" "}
              {rebateCode?.RebateCodeTypeId !== 3
                ? `${rebateCode?.RebateCodeValue}${
                    // @ts-ignore
                    rebateCode?.RebateCodeCurrencySign || ""
                  }`
                : ""}
            </p>
          ) : null}
          <p>
            <Trans id="RebateCodeUsages.validFrom" />:{" "}
            {format(new Date(rebateCode.ValidFrom), "Pp")}
          </p>
          <p>
            <Trans id="RebateCodeUsages.validTo" />:{" "}
            {format(new Date(rebateCode.ValidTo), "Pp")}
          </p>
          <p>
            <Trans id="RebateCodeUsages.numberOfUsesUsed" />:{" "}
            {rebateCode?.NumberOfUsesUsed}
          </p>
          <p>
            <Trans id="RebateCodeUsages.maxNumberOfUses" />:{" "}
            {rebateCode?.MaxNumberOfUses}
          </p>
        </ModalBody>
      ) : null}
    </Modal>
  );
};

const RebateCodeUsages = (props: Props) => {
  const customer = useAppSelector((s) => s.customer.data?.customer);
  const rebateCodeInProgressOfBuying = useAppSelector(s => s.rebateCode.entity)
  // @ts-ignore
  const rebateCodes = useGetRebateCodesQuery({
    IncludeUsages: true,
    // @ts-ignore
    IncludeArticleInformation: true
  });
  const { data } = rebateCodes;
  const [rebateCode, setRebateCode] = useState<RebateCode | undefined>(
    undefined
  );

  const dispatch = useAppDispatch();

  const toggle = () => setRebateCode(undefined);

  return (
    <>
      <RebateCodeModal rebateCode={rebateCode} toggle={toggle} />
      <RTKLoader queries={[rebateCodes]}>
        <div {...c()}>
          <Table>
            <thead>
              <tr>
                <th>
                  <Trans id="RebateCodeUsages.rebateCodeSign" />
                </th>
                <th>
                  <Trans id="RebateCodeUsages.RebateCodeType" />
                </th>
                <th>
                  <Trans id="RebateCodeUsages.validFrom" />
                </th>
                <th>
                  <Trans id="RebateCodeUsages.validTo" />
                </th>
                <th>
                  <Trans id="RebateCodeUsages.rebateCodeValueLeft" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.Results.map((rebateCode) => (
                <tr>
                  <th
                    scope="row"
                    style={{ cursor: "pointer" }}
                    onClick={() => setRebateCode(rebateCode)}
                  >
                    <Row>
                      <Col className="d-flex justify-content-center">
                    {rebateCode.RebateCodeSign}
                      </Col>
                    </Row>
                    {!rebateCode.PaymentReceived ? <Row>
                      <Col className="d-flex justify-content-center p-1">
                          <Button
                            disabled={rebateCodeInProgressOfBuying?.Id === rebateCode.Id}
                            style={{ minWidth: "100px" }}
                            color="secondary"
                            onClick={(ev) => {
                              ev.preventDefault();
                              dispatch(buyRebateCode({ rebateCode }));
                            }}
                          >
                            <Trans id="pay"></Trans>
                          </Button>
                      </Col>
                    </Row> : null}
                  </th>
                  <td>
                    <RebateCodeType
                      rebateCodeTypeId={rebateCode.RebateCodeTypeId}
                    />
                  </td>
                  <td>{format(new Date(rebateCode.ValidFrom), "Pp")}</td>
                  <td>{format(new Date(rebateCode.ValidTo), "Pp")}</td>
                  <td>
                    <RebateTotalValue rebateCode={rebateCode} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </RTKLoader>
    </>
  );
};

const RebateTotalValue: React.FC<{ rebateCode: RebateCode }> = ({
  rebateCode,
}) => {
  const {
    RebateCodeValue,
    MaxNumberOfUses,
    NumberOfUsesUsed,
    RebateCodeTypeId,
    RemainingAmount,
    PriceSign,
  } = rebateCode;
  // Percentage
  if (typeof MaxNumberOfUses === "number" && RebateCodeTypeId === 1) {
    return (
      <>
        {RebateCodeValue} (<Trans id="RebateCodeUsages.timesUsed" />{" "}
        {NumberOfUsesUsed}/{MaxNumberOfUses}
      </>
    );
  }
  // Punchcards
  else if (typeof MaxNumberOfUses === "number" && RebateCodeTypeId === 3) {
    return (
      <>
        {NumberOfUsesUsed} / {MaxNumberOfUses}
      </>
    );
  }
  // Valuecards, giftcards, currency
  else if (
    typeof MaxNumberOfUses === "number" &&
    typeof NumberOfUsesUsed === "number" &&
    typeof RebateCodeValue === "number"
  ) {
    return (
      <>
        {RemainingAmount}
        {PriceSign} ({RebateCodeValue}
        {PriceSign})
      </>
    );
  }

  return null;
};

export default RebateCodeUsages;
