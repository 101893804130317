import { createSlice, createAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';
// import {} from 'lodash';

export const STRIPE_AUTH_PATHNAME = '/stripe-auth';

interface Entity {
    accountId?: string;
    companyId?: string
}

interface InitialState {
    entity: Entity | null;
    isLoading: boolean;
    error: any;
}

export const initialState: InitialState = {
    entity: null,
    error: null,
    isLoading: false,
};

// actions
export const setCompanyId = createAction('/stripe/SET_COMPANY_ID', (companyId: string) => ({ payload: companyId }));
export const setAccountId = createAction('/stripe/SET_ACCOUNT_ID', (accountId: string) => ({ payload: accountId }));


// Slice

const stripeAuthSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(setCompanyId, (state, action) => {
            state.entity = merge(state.entity, { companyId: action.payload })
        });

        builder.addCase(setAccountId, (state, action) => {
            state.entity = merge(state.entity, { accountId: action.payload })
        });
    }
});


export default stripeAuthSlice;
