export const BookingStatusIds = {
  BOOKED: 1,
  UNBOOKED: 2,
  RESERVED: 3,
  CANCELED: 4,
  AWAITING_PAYMENT: 5,
  AWAITING_PAYMENT_NO_TIME_LIMIT: 6,
  BOOKED_AND_PAYED: 7,
  AWAITING_PAYMENT_REQUEST: 8,
  AWAITING_MONEY_TRANSFER: 9,
};

export const RECAPTCHA_SITE_KEY = "6Le9HxMUAAAAANyre4c_A3W71ECGM7bR_CafngAn";

export const NO_AUTH_PATHS = [
  "/cancel-booking",
  "/forgot-password",
  "/delete-account",
];

export const ConfigKeys = {
  COMPANY: "company",

  TIMES_LAYOUT: "timesLayout",
  TIMES_LAYOUT_WEEK: "week",
  TIMES_LAYOUT_WEEKLY_OVERVIEW: "weekly-overview",
  TIMES_LAYOUT_MONTH: "month",
  TIMES_LAYOUT_QUARTER: "quarter",
  TIMES_LAYOUT_LIST: "list",

  BOOK_METODS: "bookMethods",
  BOOK_METODS_CONTACT_INFORMATION: "contact-information",
  BOOK_METODS_LOGIN: "login",
  BOOK_METODS_CREATE_ACCOUNT: "create-account",
  BOOK_METODS_LOGIN_FACEBOOK: "login-facebook",

  HIDE_SERVICE_IMAGE: "hideServiceImage",
  SHOW_END_TIME_ON_TIMESLOTS: "showEndTimeOnTimeslots",
  HIDE_RESOURCE_IMAGE: "hideResourceImage",
  SHOW_NEXT_AVAILABLE_TIME: "showNextAvailableTime",
  SHOW_COMPANY_SUMMARY: "showCompanySummary",

  BOOK_LAYOUT: "bookLayout",
  BOOK_LAYOUT_SERVICE_BASED: "service-based",
  BOOK_LAYOUT_RESOURCE_BASED: "resource-based",
  BOOK_LAYOUT_TIME_BASED: "time-based",

  LISTING_LAYOUT: "listingLayout",
  LISTING_LAYOUT_COLUMNS: "columns",
  LISTING_LAYOUT_ROWS: "rows",
  LISTING_LAYOUT_ROWS_COMPACT: "rows-compact",
  LISTING_LAYOUT_ROW_BASED_GROUPED: "row-based-grouped",

  SERVICES_TO_SHOW_IDS: "serviceIdsToShow",
  ARTICLES_TO_SHOW_IDS: "articleIdsToShow",
  SELECTED_SERVICE: "selectedService",
  BOOKED_TIME_SLOT_TEXT: "bookedTimeSlotText",
  LANGUAGE: "language",
  PAYMENT_CONFIRMATION_URL: "paymentConfirmationURL",

  TOP_OFFSET: "topOffset",
  TRANSLATIONS: "translations",

  DARK_THEME: "darkTheme",
  PRIMARY_COLOR: "primaryColor",

  TARGETORIGIN: "targetOrigin",

  PREVENT_AUTOSCROLL: "preventAutoscroll",
  SCROLL_AFTER_STEP: "scrollAfterStep",

  SHOW_REBATE_CODE_FIELD: "showRebateCodeField",

  ANALYTICS: "analytics",
  PAYMENT_BUTTON: "paymentButton",
  IN_MODAL: "inModal",
} as const;
