import React, { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useAppSelector } from "@/hooks/hook";

interface Props {
  clientSecret: string
}
const StripeCheckout: React.FC<Props> = ({ clientSecret }) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const accountId = useAppSelector((s) => s.stripe.entity?.accountId);

  useEffect(() => {
    if (!stripePromise && accountId) {
      const _stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
        stripeAccount: accountId,
      });

      setStripePromise(_stripePromise);
    }
  }, [accountId, stripePromise]);

  return stripePromise && accountId ? (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{ clientSecret }}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  ) : null;
};

export default StripeCheckout;
