import { useAppSelector } from "@/hooks/hook";
import { ApplicationState } from "@/store";
import { RebateCode } from "@/types";
import api from "@/utils/api";
import { createArticlePaymentUrls } from "@/utils/common";
import request from "@/utils/request";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import qs from "qs";

type InitialState = {
  status: BuyStatus;
  entity?: RebateCode;
};

enum BuyStatus {
  Idle,
  Pending,
  Done,
}

type BuyRebateCodeArgs = {
  rebateCode: RebateCode;
};
export const buyRebateCode = createAsyncThunk(
  "/rebatecode/buy",
  async ({ rebateCode }: BuyRebateCodeArgs, thunkApi) => {
    try {
      const state = thunkApi.getState() as ApplicationState;
      const customerEmail = state.customer.data?.customer.Email;
      const bokameraSiteUrl = process.env.REACT_APP_BOKAMERA_SITE_URL;
      const configuration = useAppSelector((s) => s.configuration.data);

      if (!configuration) {
        throw new Error("No application configuration found.");
      }

      if (!customerEmail) {
        throw new Error("No customer email found!");
      }

      const { data: companyData } = await request(
        api.getCompanySettings({
          Id: rebateCode.CompanyId,
        })
      );

      const paymentProviderId =
        companyData.Results[0]?.BookingSettings?.PaymentProviderId;
      const isStripePayment = paymentProviderId === 5;

      const { confirmationUrl } = createArticlePaymentUrls(
        configuration,
        isStripePayment ? "embedded" : "hosted",
        rebateCode.Id,
        customerEmail
      );

      const searchParams = qs.stringify({
        bookingId: rebateCode.Id,
        companyId: rebateCode.CompanyId,
        email: customerEmail,
        paymentProviderId,
        articles: [rebateCode.Article],
      });

      let paymentUrl = `${bokameraSiteUrl}/payment?${searchParams}`;

      if (confirmationUrl) {
        paymentUrl = `${paymentUrl}&confirmationUrl=${encodeURIComponent(
          confirmationUrl.href
        )}`;
      }
      if (window.top?.location) {
        window.top.location = paymentUrl;
      }
      return thunkApi.fulfillWithValue(companyData);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const initialState: InitialState = {
  status: BuyStatus.Idle,
};
const rebateCodeSlice = createSlice({
  name: "rebateCode",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(buyRebateCode.pending, (state, action) => {
      state.status = BuyStatus.Pending;
      state.entity = action.meta.arg.rebateCode;
    });

    builder.addCase(buyRebateCode.rejected, (state, action) => {
      state.status = BuyStatus.Idle;
    });

    builder.addCase(buyRebateCode.fulfilled, (state, action) => {
      state.status = BuyStatus.Done;
    });
  },
});

export default rebateCodeSlice;
