import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import qs from "qs";
import { Spinner } from "reactstrap";
import { Trans } from "@lingui/react";
import { Button } from "bokamera-embedded-ui";

import request from "../utils/request";
import api from "../utils/api";

import StripeCheckout from "./StripeCheckout";
import { Booking } from "@/types";
import {
  getBrowserFlags,
  getCountryCode,
  getErrorMessageFromApiError,
  readConfigurationProperty,
} from "@/utils/common";
import { useAppDispatch, useAppSelector } from "@/hooks/hook";
import { setAccountId, setCompanyId } from "@/reducers/stripeSlice";

interface Props {
  bookingId: string;
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const Payment: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [checkout, setCheckout] = useState<any>();
  const [booking, setBooking] = useState<Booking | undefined>();
  const { bookingId } = props;
  const [error, setError] = useState<any>();
  const { push } = useHistory();
  const customerEmail = useAppSelector(s => s.customer.data?.customer.Email);
  const payWithPaysonV1 = booking?.Company.PaymentProviderId === 1;
  const payWithPaysonV2 = booking?.Company.PaymentProviderId === 2;
  const payWithBillmate = booking?.Company.PaymentProviderId === 3;
  const payWithStripe = booking?.Company.PaymentProviderId === 5;
  const configuration = useAppSelector((s) => s.configuration.data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bookingResponse = await request(
          api.fetchBookings({ Id: bookingId })
        );


        const booking = bookingResponse.data.Results[0] as Booking;
        dispatch(setCompanyId(booking.Company.Id));
        const { iOSSafari } = getBrowserFlags();

        if (configuration) {
          const checkoutResponse = await request(
            api.createCheckout(
              {
                Id: booking.Id,
                CompanyId: booking.Company.Id,
                PaymentProviderId: booking.Company.PaymentProviderId,
                CountryCode: getCountryCode(
                  readConfigurationProperty("language")
                ),
                Articles: [],
                UiMode:
                  configuration?.paymentButton || iOSSafari
                    ? "hosted"
                    : "embedded",
                customerEmail: customerEmail!
              },
              configuration
            )
          );

          // Payson v1 doesn't work inside iframe
          if (payWithPaysonV1) {
            const redirectUrl = checkoutResponse.data.Snippet;
            if (window.top && inIframe()) {
              window.top.location.href = redirectUrl;
            } else {
              window.location.href = redirectUrl;
            }
          }

          setBooking(booking);
          setCheckout(checkoutResponse.data);

          const intervalId = setInterval(() => {
              const paysonContainer =
              // @ts-ignore
              window?.bookingAppContainer?.querySelector("#paysonContainer") ||
              document.getElementById("paysonContainer");

            if (paysonContainer && payWithPaysonV2) {
              const scriptParentNode: Node | null = paysonContainer.parentNode;
              if (!!scriptParentNode) {
                // How to rewrite it in correct way?
                const scriptNode =
                // @ts-ignore
                  scriptParentNode.getElementsByTagName("script")[0];
                const scriptNodeWithContent = document.createElement("script");
                scriptNodeWithContent.src = scriptNode.src;
                document.head.appendChild(scriptNodeWithContent);
              }
              clearInterval(intervalId);
            }
          }, 1000);
        }
      } catch (error: any) {
        setError(getErrorMessageFromApiError(error));
      }
    };

    fetchData();
  }, [bookingId, payWithPaysonV2]);

  useEffect(() => {
    if (checkout && payWithStripe && checkout.StripeAccount) {
      dispatch(setAccountId(checkout.StripeAccount));
    } else if (
      checkout &&
      payWithStripe &&
      checkout.Url &&
      window.top?.location
    ) {
      window.top.location = checkout.data.Url;
    }
  }, [checkout, payWithStripe]);

  return (
    <div className="Payment">
      {checkout ? (
        <>
          {payWithPaysonV2 && (
            <div
              dangerouslySetInnerHTML={{ __html: checkout.Snippet }}
              data-testid="paysonContainer"
            />
          )}
          {payWithBillmate && (
            <iframe
              src={checkout.Url}
              width="100%"
              height="810px"
              frameBorder="0"
              data-testid="billmateContainer"
              title="Billmate"
            />
          )}
          {payWithStripe &&
          checkout.Status === "open" &&
          checkout.ClientSecret ? (
            <StripeCheckout clientSecret={checkout.ClientSecret} />
          ) : null}
        </>
      ) : error ? (
        <div className="container p-2 text-center">
          <p>
            <Trans
              id="payment.followingErrorHasOccured"
              values={{ bookingId }}
            />
          </p>
          <p className="text-danger">{error}</p>
          <Button
            primary
            onClick={() => {
              push("/");
              window.location.reload();
            }}
          >
            <Trans id="reload" />
          </Button>
        </div>
      ) : (
        <div className="Payment__loading">
          <Spinner color="secondary" />
        </div>
      )}
    </div>
  );
};

interface PageProps extends RouteComponentProps<{ bookingId: string }> {}

const PaymentPage: React.FC<PageProps> = (props) => (
  <Payment bookingId={props.match.params.bookingId} />
);

export default PaymentPage;
