// import history from "../history";
import axios, { AxiosRequestConfig, AxiosPromise } from "axios";
import keycloak, { REFRESH_TOKEN_INTERVAL } from "@/keycloak";
import { getCountryCode, getXlanguageCode, readConfigurationProperty } from "./common";
import qs from "qs";



/**
 * Create an Axios Client with defaults
 */
export const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
    "x-language": getXlanguageCode(),
  },
});


/**
 * Request Wrapper with default success/error actions
 */
const request = function (options?: AxiosRequestConfig): AxiosPromise {
  const headers: any = {};

  if (keycloak && keycloak.refreshToken && keycloak.token) {
    headers["x-ss-id"] = keycloak.sessionId;
    headers["Authorization"] = "Bearer " + keycloak.token;
  }
  
  return client({
    ...options,
    headers: { ...headers, ...(options || {}).headers },
    paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' })
    },
  });
};


export default request;
