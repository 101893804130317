import { Configuration } from "@/types";
import { createSlice } from "@reduxjs/toolkit";


type InitialState = {
    data?: Configuration
}
const initialState: InitialState = {
    data: undefined
}

const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        addConfiguration: (state, { payload }) => {
            delete payload.state;
            delete payload.session_state;
            delete payload.code;
            
            state.data = payload
        }
    }
});

export const { addConfiguration } = configurationSlice.actions;

export default configurationSlice;