import React, { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import FieldError from './FieldError';
import {
    Card,
    Button,
    ButtonGroup,
    Form,
    Input,
    FormGroup,
    Alert,
    Label,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { isSameDay, differenceInDays, isAfter, toDate as fnsToDate } from 'date-fns';
import BEMHelper from 'react-bem-helper';

import { BookingStatusIds } from '@/constants';
import { format, parseISO } from '@/utils/localizedDateFns';
import { Booking, Rating } from '@/types';
import { Trans } from '@lingui/react';
import Stars from './Stars';
import api, { UserRatingData } from '../utils/api';
import request from '../utils/request';
import useRating from '@/hooks/useRating';
import { isRatingVisible } from './BookingReviewTeasers';

declare global {
    var parentIFrame: any;
  }

interface BookingProps {
    booking: Booking;
    onCancelBooking?: (bookingId: number) => Promise<void>;
    onPayBooking?: (bookingId: number) => void;
}

interface BookingRatingState {
    bookingId: number;
    ratingScore: number;
    ratingCode: string;
    error?: string;
}

const initialBookingRating: BookingRatingState = {
    bookingId: 0,
    ratingScore: 0,
    ratingCode: '',
    error: '',
};

const c = new BEMHelper('BookingCard');

const BookingCard = ({ booking, onCancelBooking, onPayBooking }: BookingProps) => {
    const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const { bookingRating, currentRating, setCurrentRating, setIsSubmitted } = useRating({
        booking,
    });

    const dateFormat = 'PP';
    const timeFormat = 'p';
    const fromDate = parseISO(booking.From);
    const toDate = parseISO(booking.To);
    const lastTimeToCancelDate = parseISO(booking.LastTimeToUnBook);
    const multiDay = !isSameDay(fromDate, toDate);

    const date = multiDay ? (
        <div {...c('datetime')}>
            <div {...c('time')}>{format(fromDate, timeFormat)}</div>{' '}
            <div {...c('date')}>{format(fromDate, dateFormat)}</div>
            {' - '}
            <div {...c('time')}>{format(toDate, timeFormat)}</div>{' '}
            <div {...c('date')}>{format(toDate, dateFormat)}</div>
        </div>
    ) : (
        <div {...c('datetime')}>
            <div {...c('time')}>{format(fromDate, timeFormat)}</div>
            {' - '}
            <div {...c('time')}>{format(toDate, timeFormat)}</div>{' '}
            <div {...c('date')}>{format(toDate, dateFormat)}</div>
        </div>
    );

    function toggleCancelConfirm() {
        setCancelConfirmOpen(!cancelConfirmOpen);
    }

    const handlePayBooking = useCallback(() => {
        if (onPayBooking) {
            onPayBooking(booking.Id);
        }
    }, [booking, onPayBooking]);

    const handleCancel = useCallback(() => {
        if (onCancelBooking) {
            setCancelLoading(true);
            onCancelBooking(booking.Id)
                .then(() => {
                    setCancelConfirmOpen(false);
                })
                .finally(() => {
                    setCancelLoading(true);
                });
        }
    }, [booking, onCancelBooking, setCancelConfirmOpen]);

    const canBeCancelled =
        [BookingStatusIds.UNBOOKED, BookingStatusIds.CANCELED].indexOf(booking.StatusId) === -1 &&
        isAfter(lastTimeToCancelDate, new Date());

    const canBePaid =
        booking?.Service?.IsPaymentEnabled &&
        [
            BookingStatusIds.AWAITING_PAYMENT,
            BookingStatusIds.AWAITING_PAYMENT_NO_TIME_LIMIT,
        ].indexOf(booking.StatusId) > -1;

    const isCancellable = differenceInDays(fromDate, lastTimeToCancelDate) <= 365;
    const ratingScore = currentRating?.ratingScore || bookingRating?.RatingScore || 0;

    const to = fnsToDate(fromDate);
    const now = new Date();
    const isPast = to < now;

    return (
      <Card key={booking.Id} {...c()}>
        {cancelConfirmOpen ? (
          <div {...c(`flex flex-column`)}>
            <div>
                <Trans id="confirmBookingCancelText"></Trans>
            </div>
            <div {...c("serviceName")}>{booking.Service.Name}</div>
            <div>
                {date}
            </div>
            <div {...c('flex')}>
              <Button
                style={{ marginRight: '10px', minWidth: "100px" }}
                color="secondary"
                onClick={toggleCancelConfirm}
              >
                <Trans id="returnBack"></Trans>
              </Button>
              <Button
                style={{ minWidth: "100px" }}
                color="danger"
                onClick={handleCancel}
                disabled={cancelLoading}
              >
                {cancelLoading && <Spinner size="sm" />}{" "}
                <Trans id="confirm"></Trans>
              </Button>
            </div>
          </div>
        ) : null}

        <div {...c(`flex ${cancelConfirmOpen ? "hidden" : ""}`)}>
          <div {...c("left")}>
            <div
              style={{ backgroundImage: `url(${booking.Service.ImageUrl})` }}
              {...c("img")}
            />
            {canBeCancelled ? (
              <Button
                {...c("action")}
                style={{ minWidth: "100px" }}
                color="danger"
                onClick={toggleCancelConfirm}
              >
                <Trans id="unbook"></Trans>
              </Button>
            ) : null}
            {canBePaid ? (
              <Button {...c("action")} primary onClick={handlePayBooking}>
                <Trans id="pay"></Trans>
              </Button>
            ) : null}
          </div>
          <div {...c("right")}>
            <div {...c("header")}>
              <div {...c("serviceName")}>{booking.Service.Name}</div>
              {booking.Status === "Booked" && booking.RatingCode && isPast ? (
                <div>
                  <Stars
                    active
                    key={booking.Id}
                    rating={ratingScore}
                    onRate={(rating) => {
                      setCurrentRating({
                        bookingId: booking.Id,
                        ratingCode: booking.RatingCode,
                        ratingScore: rating,
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div {...c("subtitle")}>
              <span {...c("id")}>{booking.Id}</span>{" "}
              <span
                {...c("status")}
                data-testid="booking-status-info"
                style={{ color: booking.StatusInfo.Color }}
              >
                <BookingStatus booking={booking} />
              </span>
            </div>
            {date}
            <dl {...c("descriptionList")}>
              <dt {...c("descriptionTitle")}>
                <Trans id="lastTimeToUnbook"></Trans>
              </dt>
              <dd {...c("descriptionDetails")}>
                {isCancellable ? (
                  format(lastTimeToCancelDate, "PPp")
                ) : (
                  <Trans id="notCancellable"></Trans>
                )}
              </dd>
            </dl>
            {booking.BookedResourceTypes
              ? booking.BookedResourceTypes.map((resourceType) => (
                  <dl {...c("descriptionList")}>
                    <dt {...c("descriptionTitle")}>{resourceType.Name}</dt>
                    <dd {...c("descriptionDetails")}>
                      {resourceType.Resources.map(
                        (resource) => resource.Name
                      ).join(",")}
                    </dd>
                  </dl>
                ))
              : null}
            {booking.CustomFieldValues.map((customFieldValue, index) => (
              <dl {...c("descriptionList")} key={index}>
                <dt {...c("descriptionTitle")}>{customFieldValue.Name}</dt>
                <dd {...c("descriptionDetails")}>{customFieldValue.Value}</dd>
              </dl>
            ))}
          </div>
        </div>

        <div
          {...c("cardReview")}
          style={{
            maxHeight: currentRating.ratingScore > 0 ? "500px" : 0,
          }}
        >
          <div {...c("cardReview__heading")}>
            <Trans id="review.submitReview" />
          </div>
          <CardForm
            booking={booking}
            bookingRating={bookingRating}
            currentRating={currentRating}
            setCurrentRating={setCurrentRating}
            setIsSubmitted={setIsSubmitted}
          />
        </div>
      </Card>
    );
};

const CardForm = ({
    booking,
    bookingRating,
    currentRating,
    setCurrentRating,
    setIsSubmitted = () => {},
}: {
    booking: Booking;
    bookingRating: Rating;
    currentRating: BookingRatingState;
    setCurrentRating: React.Dispatch<BookingRatingState | null>;
    setIsSubmitted?: React.Dispatch<boolean>;
}) => {
    const authorName = booking.Customer
        ? `${booking.Customer.Firstname} ${booking.Customer.Lastname}`
        : '';
    const autofocusRef = useRef<HTMLElement>();

    useEffect(() => {
        if (autofocusRef && autofocusRef.current && !window.parentIFrame) {
            autofocusRef.current.focus();
        }
    }, [currentRating]);

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    CompanyId: booking.CompanyId,
                    Identifier: booking.RatingCode,
                    BookingId: booking.Id,
                    RatingScore: currentRating.ratingScore,
                    Review: {
                        Title: bookingRating?.Review?.Title || '',
                        Author: authorName,
                        Description: bookingRating?.Review?.Description || '',
                    },
                } as UserRatingData
            }
            onSubmit={async (ratingValues, actions) => {
                actions.setSubmitting(true);

                const addOrUpdateRating = bookingRating?.RatingScore
                    ? api.updateRating
                    : api.addRating;

                request(addOrUpdateRating(ratingValues))
                    .then((response) => {
                        actions.setSubmitting(false);
                        setIsSubmitted(true);
                    })
                    .catch((error: any) => {
                        actions.setSubmitting(false);
                        if (error?.response?.data?.ResponseStatus) {
                            actions.setStatus('Success!');
                            actions.setStatus(error.response.data.ResponseStatus.Message);
                            actions.setErrors(
                                error.response.data.ResponseStatus.Errors.reduce(
                                    (acc: any, error: any) => ({
                                        ...acc,
                                        [error.FieldName]: error.Message,
                                    }),
                                    {}
                                )
                            );
                        } else if (error.message) {
                            actions.setStatus(error.message);
                        }
                    });
            }}
        >
            {(props: any) => {
                const { handleSubmit, values, isSubmitting, status } = props;

                return (
                    <Form
                        key={`${values.BookingId}-${values.BookingRating}`}
                        onSubmit={handleSubmit}
                    >
                        <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
                            <Field type="hidden" value={currentRating.ratingScore} name="rating" />
                        </FormGroup>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                            <Label htmlFor="Review.Author">
                                <Trans id="review.author" />
                            </Label>
                            <Field
                                {...c('input')}
                                name="Review.Author"
                                id="Review.Author"
                                disabled={isSubmitting}
                            />
                            <FieldError name="Review.Author" />
                        </FormGroup>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                            <Label htmlFor="Review.Title">
                                <Trans id="review.title" />
                            </Label>
                            <Field
                                {...c('input')}
                                innerRef={autofocusRef}
                                name="Review.Title"
                                id="Review.Title"
                                autoComplete="off"
                                disabled={isSubmitting}
                            />
                            <FieldError name="Review.Title" />
                        </FormGroup>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                            <Label htmlFor="Review.Description">
                                <Trans id="review.description" />
                            </Label>
                            <Field
                                {...c('textarea')}
                                name="Review.Description"
                                id="Review.Description"
                                autoComplete="off"
                                component="textarea"
                            />
                            <FieldError name="Review.Description" />
                        </FormGroup>
                        <ButtonGroup style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setCurrentRating(null);
                                }}
                            >
                                <Trans id="returnBack"></Trans>
                            </Button>
                            <Button tabIndex={0} onClick={handleSubmit} color="primary">
                                {isSubmitting ? (
                                    <Spinner
                                        style={{
                                            position: 'absolute',
                                            right: '1.25rem',
                                            width: '1.25rem',
                                            height: '1.25rem',
                                        }}
                                    />
                                ) : null}
                                <Trans id="save"></Trans>
                            </Button>
                        </ButtonGroup>
                        <FormGroup style={{ marginTop: '15px' }}>
                            {status && <Alert color="danger">{status}</Alert>}
                        </FormGroup>
                    </Form>
                );
            }}
        </Formik>
    );
};

const FEEDBACK_DURATION = 4000;
const Small = ({
    booking,
    onSubmit,
}: {
    booking: Booking;
    onSubmit: (booking: Booking) => void;
}) => {
    const {
        bookingRating,
        currentRating,
        setCurrentRating,
        isSubmitted,
        setIsSubmitted,
    } = useRating({ booking });
    const ratingScore = currentRating?.ratingScore || bookingRating?.RatingScore || 0;
    const isActive = currentRating.ratingScore;
    const [isRatingDone, setRatingDone] = useState(false);

    const toDate = parseISO(booking.To);
    const fromDate = parseISO(booking.From);
    const dateFormat = 'PP';
    const timeFormat = 'p';
    const to = fnsToDate(fromDate);
    const now = new Date();
    const isPast = to < now;

    const date = (
        <div {...c('datetime')}>
            <div {...c('time')}>{format(fromDate, timeFormat)}</div>{' '}
            <div {...c('date')}>{format(fromDate, dateFormat)}</div>
        </div>
    );
    useEffect(() => {
        if (isSubmitted) {
            setTimeout(() => {
                onSubmit(booking);
                setRatingDone(true);
            }, FEEDBACK_DURATION);
        }
    }, [isSubmitted]);

    useEffect(() => {
        if (!isRatingDone) return;

        api.fetchRatings({
            BookingId: booking.Id,
            CompanyId: booking.CompanyId,
            Active: true,
        });
    }, [isRatingDone]);

    return isRatingVisible(booking) && !isRatingDone ? (
        <>
            <Card
                key={booking.Id}
                style={{
                    minWidth: isActive ? '40%' : undefined,
                    maxHeight: isActive ? '1000px' : undefined,
                }}
                title={booking.Service.Name}
                {...c(`SmallCard ${isActive ? 'active' : ''}`)}
            >
                {!isRatingDone && isSubmitted ? (
                    <>
                        <div style={{ marginTop: '10px' }}>{booking.Service.Name}</div>
                        <Stars active key={booking.Id} rating={ratingScore} />
                        <span>
                            <Trans id={'BookingCard.thanksForRating'} />
                        </span>
                    </>
                ) : (
                    <>
                        <div {...c('flex itemCenter justifyCenter')}>
                            <div {...c('left')}>
                                <div
                                    style={{
                                        backgroundImage: `url(${booking.Service.ImageUrl})`,
                                    }}
                                    {...c('img', 'small')}
                                />
                            </div>
                            <div
                                {...c('right')}
                                style={{ flexDirection: 'column', alignItems: 'center' }}
                            >
                                <div {...c('serviceName')}>{booking.Service.Name}</div>
                                <div>{date}</div>
                            </div>
                        </div>
                        <Stars
                            active
                            key={booking.Id}
                            onRate={(rating) => {
                                setCurrentRating({
                                    bookingId: booking.Id,
                                    ratingCode: booking.RatingCode,
                                    ratingScore: rating,
                                });
                            }}
                            rating={ratingScore}
                        />
                        <div
                            {...c('cardReview')}
                            style={{
                                maxHeight: currentRating.ratingScore > 0 ? '500px' : 0,
                            }}
                        >
                            <div {...c('cardReview__heading')}>
                                <Trans id="review.submitReview" />
                            </div>
                            <CardForm
                                booking={booking}
                                bookingRating={bookingRating}
                                currentRating={currentRating}
                                setCurrentRating={setCurrentRating}
                                setIsSubmitted={setIsSubmitted}
                            />
                        </div>
                    </>
                )}
            </Card>
        </>
    ) : null;
};

BookingCard.Small = Small;

interface BookingStatusProps {
    booking: Booking;
}

export const BookingStatus = ({ booking }: BookingStatusProps) => {
    if (!booking) return null;
    
    return <>{booking.StatusName}</>;
};

export default BookingCard;
