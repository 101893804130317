import React, { useEffect } from "react";
import {client} from "../utils/request";
import { useHistory } from "react-router";
import { Spinner, Container, Row, Col } from 'reactstrap';
import { useToasts } from "react-toast-notifications";
import keycloak from "@/keycloak";
import { useAppSelector } from "@/hooks/hook";

interface Props {
  children: React.ReactNode;
}


export interface AuthStateInterface {
  isAuth: any;
}

export interface AuthContextInterface extends AuthStateInterface {
  login: Function;
  logout: Function;
}

const defaultValue = {
  isAuth: false,
  login: () => { /* intentional */ },
  logout: () => {  /* intentional */ },
};

export const AuthContext = React.createContext<AuthContextInterface>(
  defaultValue
);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const { addToast, toastStack } = useToasts();
  const { isLoggedIn } = useAppSelector(s => s.authenticate);

  useEffect(() => {
    const responseIntercpetor = client.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: any) {
        if (error?.response && error?.response?.status === 401 || error?.response?.status === 405) {
          addToast(`${error.response.status} ${error.response.statusText}`, {
            appearance: "error",
            autoDismiss: true,
            onDismiss: () => {
              keycloak.logout()
              .catch(err => {
                console.log(err);
              });
            }
          });
          console.log(error.response);
          history.replace("/login");
        }

        return Promise.reject(error);
      }
    );

    return () => {
      client.interceptors.response.eject(responseIntercpetor);
    }
  }, [toastStack])

  useEffect(() => {
     if (isLoggedIn) {
      history.push("/");
    }

    const handleMessage = (evt: MessageEvent) => {
      if (evt.data === '[iFrameSizer]message:"restart"') {
        if ("parentIFrame" in window) {
          const parentIFrame = window["parentIFrame"];
          parentIFrame.close();
        }
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [isLoggedIn]);

  const login = () => {
    keycloak.login()
    .catch(err => {
      console.log(err);
    });
  }

  const logout = () => {
    keycloak.logout();
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth: isLoggedIn,
        login,
        logout,
      }}
    >
      {!isLoggedIn ? (
        <Container>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Spinner role="alert" aria-busy="true" size="medium" />
            </Col>
          </Row>
        </Container>
      ) : children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
export { AuthProvider, AuthConsumer };
