import { useAppSelector } from "@/hooks/hook";
import React, { FunctionComponent } from "react";
import { RouteProps, Route } from "react-router";

export const ProtectedRoute: FunctionComponent<RouteProps> = ({
  component: Component, ...rest
}) => {
  const { isLoggedIn } = useAppSelector(s => s.authenticate);

  return Component ? (
    <React.Fragment>
      <Route
        render={(props) => (isLoggedIn ? <Component {...props} /> : null)}
        {...rest} />
    </React.Fragment>
  ) : null;
};
